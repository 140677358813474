import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Map, Control, DomUtil, ZoomAnimEvent , Layer, MapOptions, tileLayer, latLng } from 'leaflet';
import { GestionDepartamentoService } from 'src/app/_services/gestion-secretarias/gestion-departamento';
import * as L from 'leaflet';
import { GestionSecretariasService } from 'src/app/_services/gestion-secretarias/gestion-secretarias';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {
  
  @Output() map$: EventEmitter<Map> = new EventEmitter;
  @Output() zoom$: EventEmitter<number> = new EventEmitter;
  @Input() options: MapOptions= {
    /*layers: [
      tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })
    ],*/
    zoom: 5,
    center: latLng(4.31, -74.18)
  };

  public map: Map;
  public zoom: number;
  public icon: any;
  geoinfo: any;

  constructor(
    private gestionDepartamentoService: GestionDepartamentoService,
    private gestionSecretariasService: GestionSecretariasService
  ) { }

  ngOnInit() {
    this.icon = L.icon(
      {
        iconUrl: 'assets/icons/ubicacion-mapa.svg',
        iconSize: [20, 20], // size of the icon
      });
  }

  ngOnDestroy() {
    //this.map.clearAllEventListeners;
    //this.map.remove();
  };

  fetchSecretarias(map: Map): void {
    this.gestionSecretariasService.getSecretariasActive()
    .subscribe(response => {
      response.forEach(x => {
        if(x.latitud !== undefined && x.longitud !== undefined) {
          let team = '';
          if(x.equipo !== undefined){
            x.equipo.forEach(y => {
              team += `<li>${y.nombre} ${y.apellido} - ${y.cargo}</li>`
            }); 
          }

          let url = x.url === undefined ? '' : (x.url.includes('http://') || x.url.includes('https://')) ? x.url : `http://${x.url}`;
          let info = 
            `<div class="card-title text-center"><b>${x.titulo}</b></div>
              <p class="card-text">
              <b>Secretaria/o:</b> ${x.secretario === undefined ? '' : x.secretario}<br />
              <b>Dirección física:</b> ${x.direccion === undefined ? '' : x.direccion}<br />
              <b>Teléfono de contacto:</b> ${x.telefonoContacto === undefined ? '' : x.telefonoContacto}<br />
              <b>Correo electrónico:</b> ${x.correoInstitucional === undefined ? '' : x.correoInstitucional}<br />
              <b>Sitio web:</b> <a href="${url}" target="_blank" class="secretaria-link">${url}</a><br />
              <b>Integrantes:</b>
              <ul>${team}<ul>
            </p>`;
          L.marker([x.latitud, x.longitud], {icon: this.icon}).addTo(map).bindPopup(info);        
        }
      });
    });
  }

  onMapReady(map: Map) {
    this.gestionDepartamentoService.geojson()
    .subscribe((json: any) => {
      this.geoinfo = json;
      this.fetchSecretarias(map);
      L.geoJSON(this.geoinfo).addTo(map);
      this.map$.emit(map);
      this.zoom = map.getZoom();
      this.zoom$.emit(this.zoom);
    });
  }

  onMapZoomEnd(e: ZoomAnimEvent) {
    this.zoom = e.target.getZoom();
    this.zoom$.emit(this.zoom);
  }
}