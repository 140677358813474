import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Inject,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { TemplatesService } from 'src/app/_services/micrositios/templates.service';

import { Template } from 'src/app/_model/micrositios/template.model';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-micrositio-view',
  templateUrl: './micrositio-view.component.html',
  styleUrls: ['./micrositio-view.component.scss'],
})
export class MicrositioViewComponent implements OnInit {
  @ViewChild('iframe', { static: true }) iframe: ElementRef<HTMLIFrameElement>;

  APIUrl = environment.apiUrl;
  id: number;
  template: Template;
  content: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private templatesService: TemplatesService,
    private activedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.fetchTemplate();
  }

  fetchTemplate() {
    this.activedRoute.params.subscribe((params: Params) => {
      this.id = params.id;
      this.templatesService
        .getMicrositeTemplateDetailByMicrositeId(this.id)
        .subscribe((micrositio) => {
          this.template = micrositio[0];
          this.onload_iframe();
        });
    });
  }

  onload_iframe() {
    this.iframe.nativeElement.srcdoc = `
    <!doctypehtml>
    <html>
      <head>
      <link href="https://fonts.googleapis.com/css2?family=Work+Sans:wght@300&display=swap" rel="stylesheet">
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" />
        <link rel="stylesheet" href="https://cdn.datatables.net/1.10.21/css/jquery.dataTables.min.css" />
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@splidejs/splide@latest/dist/css/splide.min.css">
        <link rel="stylesheet" href="assets/micrositios/css/style.css" type="text/css" />
        <style>
        ${this.template.css}
        </style>

        <script src="https://code.jquery.com/jquery-3.5.1.js"></script>
        <script src="https://cdn.jsdelivr.net/npm/@splidejs/splide@latest/dist/js/splide.min.js"></script>
        <script src="https://cdn.datatables.net/1.10.21/js/jquery.dataTables.min.js"></script>
        <script type="text/javascript" src="assets/micrositios/js/moment.min.js"></script>
        <script type="text/javascript" src="assets/micrositios/js/datatable.js"></script>
        <script type="text/javascript" src="assets/micrositios/js/gestorcontenidos.js"></script>
      </head>
      <body>
        ${this.template.html}
        <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js"></script>
        <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
        <script type="text/javascript" src="assets/micrositios/js/scriptCarouselMicrositios.js"></script>
        <script type="text/javascript" src="assets/micrositios/js/scriptGaleriaMicrositios.js"></script>
        <script type="text/javascript" src="assets/micrositios/js/InicializarComponentes.js"></script>
      </body>
    </html>`;


    this.iframe.nativeElement.addEventListener('load', (e) => {
      let link: any;
      for (link of Array.from(
        document.querySelector('iframe').contentDocument.querySelectorAll('a')
      )) {
        const a = link.outerHTML;
        const b = a.substring(a.lastIndexOf('href="') + 6);
        const c = b.substring(0, b.indexOf('"'));
        if (c.includes('www')) {
          if (link.target !== '_blank') {
            link.target = '_top';
          }
          let a = '//';
          if (!c.includes(`http:${a}`) && !c.includes(`https:${a}`)) {
            link.href = `http://${c}`;
          }
        } else {
          if (link.href.lastIndexOf('#') !== -1) {
            const id = link.href.substring(
              link.href.lastIndexOf('#') + 1,
              link.href.length
            );
            if (
              link.href === '#' ||
              document
                .querySelector('iframe')
                .contentDocument.getElementById(id) == null
            ) {
              link.href = 'JavaScript:Void(0);';
            } else {
              link.removeAttribute('href');
              link.classList.add('hover');
              link.addEventListener('click', (e) => {
                e.preventDefault();
                document
                  .querySelector('iframe')
                  .contentDocument.scrollingElement.querySelector(`#${id}`)
                  .scrollIntoView();
                return false;
              });
            }
          }
        }
      }
    });
  }
}
