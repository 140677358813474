import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl} from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-contenido-qinfo',
  templateUrl: './contenido-qinfo.component.html',
  styleUrls: ['./contenido-qinfo.component.scss']
})
export class ContenidoQinfoComponent implements OnInit {
  
  @Input() url = '';
  @Input() nombre = '';
  safeUrl: SafeUrl;

  constructor(
    public modal: NgbActiveModal,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }
}
