import { Routes, RouterModule } from '@angular/router';
import { AutodiagnosticoComponent } from './_pages/diagnostico/autodiagnostico/autodiagnostico.component';
import { HomeComponent } from './_pages/home/home.component';
import { LoginComponent } from './_pages/login/login.component';
import { ResultadosComponent } from './_pages/diagnostico/resultados/resultados.component';
import { PreguntasFrecuentesComponent } from './_pages/contacto/preguntas-frecuentes/preguntas-frecuentes.component';
import { AsistenciaComponent } from './_pages/contacto/asistencia/asistencia.component';
import { AdministracionComponent } from './_pages/administracion/administracion.component';
import { ChangePasswordComponent } from './_pages/login/change-password/change-password.component';
import { GestionUsuariosComponent } from './_pages/administracion/gestion-usuarios/gestion-usuarios.component';
import { RestorePasswordComponent } from './_pages/login/restore-password/restore-password.component';
import { LayoutComponent } from './_pages/layout/layout.component';
import { AuthGuard } from './_guards/auth.guard';
import { MicrositioViewComponent } from './_pages/micrositios/components/micrositio-view/micrositio-view.component';
import { PreguntasComponent } from './_pages/preguntas/preguntas.component';
import { MenuDinamicoComponent } from './_pages/menu-dinamico/menu-dinamico.component';
import { QuieroinformarmeComponent } from './_pages/quieroinformarme/quieroinformarme.component';

const routes: Routes = [
  { path: 'publicaciones/:id', component: MicrositioViewComponent },
  { path: 'preguntas', component: PreguntasComponent },
  { path: 'preguntas/:idMicrositio', component: PreguntasComponent },
  { path: 'menudinamico', component: MenuDinamicoComponent },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'admin',
        component: AdministracionComponent
      },
      {
        path: 'admin/:id',
        component: AdministracionComponent
      },
      {
        path: 'autodiagnostico',
        component: AutodiagnosticoComponent
      },
      {
        path: 'asistencia',
        component: AsistenciaComponent
      },
      {
        path: 'changepassword',
        component: ChangePasswordComponent
      },
      {
        path: 'gestionUsuarios',
        component: GestionUsuariosComponent
      },
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'preguntas_frecuentes',
        component: PreguntasFrecuentesComponent
      },
      {
        path: 'quiero_info_contenido',
        component: QuieroinformarmeComponent
      },
      {
        path: 'resultados',
        component: ResultadosComponent
      },
      {
        path: 'restorePassword',
        component: RestorePasswordComponent
      },
      {
        path: 'gestion-quiero-aprender',
        //canActivate: [AuthGuard],
        loadChildren: () => import('./_pages/quiero-aprender-editor/quiero-aprender-editor.module').then(m => m.QuieroAprenderEditorModule)
      },
      {
        path: 'quiero_aprender',
        loadChildren: () => import('./_pages/quiero-aprender-editor/quiero-aprender-editor.module').then(m => m.QuieroAprenderEditorModule)
      },
      {
        path: 'quiero-conectarme',
        loadChildren: () => import('./_pages/quiero-conectarme/quiero-conectarme.module').then(m => m.QuieroConectarmeModule)
      },
      {
        path: 'gestion-quiero-conectarme',
        canActivate: [AuthGuard],
        loadChildren: () => import('./_pages/quiero-conectarme/quiero-conectarme.module').then(m => m.QuieroConectarmeModule)
      },
      {
        path: 'micrositios',
        canActivate: [AuthGuard],
        loadChildren: () => import('./_pages/micrositios/micrositios.module').then(m => m.MicrositiosModule)
      },
      {
        path: 'gestion',
        canActivate: [AuthGuard],
        loadChildren: () => import('./_pages/gestor-contenidos/gestor-contenidos.module').then(m => m.GestorContenidosModule)
      },
      {
        path: 'gestion_preguntas_frecuentes',
        canActivate: [AuthGuard],
        loadChildren: () => import('./_pages/gestion-preguntas-frecuentes/gestion-preguntas-frecuentes.module')
          .then(m => m.GestionPreguntasFrecuentesModule)
      },
      {
        path: 'calendario',
        loadChildren: () => import('./_pages/calendario/calendario.module').then(m => m.CalendarioModule)
      },
      {
        path: 'gestionroles',
        // canActivate: [AuthGuard],
        loadChildren: () => import('./_pages/gestor-roles/gestor-roles.module').then(m => m.GestorRolesModule)
      },
      {
        path: 'gestionreportes',
        // canActivate: [AuthGuard],
        loadChildren: () => import('./_pages/gestor-reportes/gestor-reportes.module').then(m => m.GestorReportesModule)
      },
      {
        path: 'gestioncapacidades',
        // canActivate: [AuthGuard],
        loadChildren: () => import('./_pages/gestion-capacidades/gestion-capacidades.module').then(m => m.GestionCapacidadesModule)
      },
      {
        path: 'gestionsecretaria',
        // canActivate: [AuthGuard],
        loadChildren: () => import('./_pages/gestion-secretaria/gestion-secretaria.module').then(m => m.GestionSecretariaModule)
      },
      {
        path: '',
        // canActivate: [AuthGuard],
        loadChildren: () => import('./_pages/home-editor/home-editor.module').then(m => m.HomeEditorModule)
      },
      {
        path: 'editor',
        // canActivate: [AuthGuard],
        loadChildren: () => import('./_pages/home-editor/home-editor.module').then(m => m.HomeEditorModule)
      },
      {
        path: 'quiero',
        // canActivate: [AuthGuard],
        loadChildren: () => import('./_pages/quiero-section/quiero-section.module').then(m => m.QuieroSectionModule)
      },
    ]
  },
  {
    path: 'plug-micrositios',
    loadChildren: () => import('./_pages/plug-micrositios/plug-micrositios.module').then(m => m.PlugMicrositiosModule)
  },
];

export const routing = RouterModule.forRoot(routes,
  {
    onSameUrlNavigation: 'reload', enableTracing: false// <-- debugging purposes only
    // scrollPositionRestoration: 'enabled'
  }
);
