import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  public closeActionByOutsideClickMenu: any;
  public identifyClickOutSideMenu(event) {
    this.closeActionByOutsideClickMenu = event;
  }
}
