import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { Router } from '@angular/router';
import { HelperService } from 'src/app/_services/helper.service';
import { first } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponent } from 'src/app/_shared/modals/alert-modal/alert-modal.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public res: any;
  lookpass: boolean;
  countInvalido = 0;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private helper: HelperService,
    private modalService: NgbModal,
    private auth: AuthenticationService
  ) { }

  public loginForm: FormGroup;

  ngOnInit() {
    this.auth.actualUser$.subscribe((data) => {
      (data === null) ? "" : this.router.navigate(["/menudinamico"]);
    });
    this.loginForm = this.formBuilder.group({
      Email: ['', Validators.required],
      Password: ['', Validators.required]
    });
  }

  login() {
    if (this.loginForm.invalid) {
      this.alertModal(`<p class='mt-2 py-4 col-sm-12 text-center'>Completa el formulario.</p>`);
      return;
    }
    this.authenticationService.login(this.loginForm.value.Email, this.loginForm.value.Password).
      pipe(first()).subscribe(dep => {
        this.res = dep;
        if(this.res.idUsuario === undefined || this.res.idUsuario === null) {
          if(this.res === 'El usuario y/o la contraseña no son válidos.') {
            if(this.countInvalido >= 3) {
              let url = window.location.href;
              url = url.replace('login', 'restorePassword');
              this.res = `Su usuario y/o contraseña continúa siendo inválidos, para autenticarse le sugerimos <b><a href="${url}">Restablecer su contraseña</a></b>.`;
            }
            this.countInvalido++;
          }
          this.alertModal(`<p class='mt-2 py-4 col-sm-12 text-center'>${this.res}</p>`);
        } else {
          localStorage.setItem('loginUser', '1');
          if (this.res.cambioClave) {
            this.router.navigate(['/changepassword'], {});
          } else {
            this.router.navigate(['/menudinamico'], {});
          }
          this.authenticationService.reloadSession()
        }
      }, err => {
        this.alertModal(`<p class='mt-2 py-4 col-sm-12 text-center'>${err.error.Message}</p>`);
      });
  }

  navigateHome() {
    this.router.navigate(['/login']);
  }

  do_LookMyPass(param?: string) {
    (this.lookpass) ? this.lookpass = false : this.lookpass = true;
  }

  alertModal(message: string) {
    const ref = this.modalService.open(AlertModalComponent, {
      centered: true,
      backdrop: 'static',
      keyboard: false
    });
    ref.componentInstance.message = message;
  }
}
