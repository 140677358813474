import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AutodiagnosticoComponent } from '../diagnostico/autodiagnostico/autodiagnostico.component'
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { Router, NavigationStart, Event as NavigationEvent } from '@angular/router';
import 'rxjs/add/operator/pairwise';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnChanges {

  userlogged: any;
  autodiagnostico: any;
  home: number;
  quieroInformarme: number;
  quieroAprender: number;
  contacto: number;
  navShow: any;
  constructor(private auth: AuthenticationService, private router: Router) {
  }
  @Input() childData: boolean;
  @Input() closeActionByOutsideClickPattern: any;
  ngOnChanges(changes: SimpleChanges) {
    const changeEvent = changes["childData"];    
    const closeEvent = changes["closeActionByOutsideClickPattern"];
    if (changeEvent && changeEvent.currentValue != undefined) {
      if (changeEvent.currentValue) {
        this.mainActive = true;
      } else {
        this.mainActive = false;
      }
    }    
    if (closeEvent && closeEvent.currentValue != undefined) {
      this.identifyClickOutSide(closeEvent.currentValue);
    }
    
  }

  ngOnInit() {
    this.auth.actualUser$.subscribe(data => {
      this.userlogged = data;
      this.activeMainIcon(this.router.url);
      this.router.events.subscribe(
        (event: NavigationEvent) => {
          if (event instanceof NavigationStart) {
            this.activeMainIcon(event.url);
          }
        });
    });
  }

  public mainActive: boolean = false;
  activeMainIcon(url) {
    if (url == "/menudinamico") {
      this.mainActive = false;
    } else {
      this.mainActive = true;
    }
  }

  do_Logout() {
    this.auth.logout();
    this.router.navigate(['/login']);
  }

  activeMenu(menu: string) {
    console.log(menu);
    if (menu == 'home') {
      this.home = 1;
      this.autodiagnostico = 0;
      this.quieroInformarme = 0;
      this.quieroAprender = 0;
      this.contacto = 0;
    } else if (menu == 'autodiagnostico') {
      this.autodiagnostico = 1;
      this.home = 0;
      this.quieroInformarme = 0;
      this.quieroAprender = 0;
      this.contacto = 0;
    } else if (menu == 'quieroInformarme') {
      this.quieroInformarme = 1;
      this.autodiagnostico = 0;
      this.home = 0;
      this.quieroAprender = 0;
      this.contacto = 0;
    } else if (menu == 'quieroAprender') {
      this.quieroAprender = 1;
      this.quieroInformarme = 0;
      this.autodiagnostico = 0;
      this.home = 0;
      this.contacto = 0;
    } else if (menu == 'contacto') {
      this.contacto = 1;
      this.quieroAprender = 0;
      this.quieroInformarme = 0;
      this.autodiagnostico = 0;
      this.home = 0;
    }

  }

  @HostListener('window:location', ['$event'])
  onLocation(event) {
    console.log(event)
  }

  @Output() evento = new EventEmitter<boolean>();
  toHome() {
    if (localStorage.getItem('position') && localStorage.getItem('position') == "child") {
      this.evento.next(true)
    } else {
      this.router.navigate(["/menudinamico"]);
    }
  }

  public viewOption: boolean = false;
  activeOptions() {
    (this.viewOption) ? this.viewOption = false : this.viewOption = true;
  }
  public identifyClickOutSide(e) {
    var id = 'buttonOptions';
    var b = document.getElementById(id);
    if(b) {
      if (!b.contains(e.target)) {
        this.viewOption = false
      }
    }    
  }
}
