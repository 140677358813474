import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BannersupComponent } from './_pages/bannersup/bannersup.component';
import { MenuComponent } from './_pages/menu/menu.component';
import { BannerprincipalComponent } from './_pages/bannerprincipal/bannerprincipal.component';
import { EstrategiaComponent } from './_pages/estrategia/estrategia.component';
import { DiagnosticoComponent } from './_pages/diagnostico/diagnostico.component';
import { QuieroinformarmeComponent } from './_pages/quieroinformarme/quieroinformarme.component';
import { ContactoComponent } from './_pages/contacto/contacto.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AutodiagnosticoComponent } from './_pages/diagnostico/autodiagnostico/autodiagnostico.component';
import { routing } from './app-routing.module';
import { HomeComponent } from './_pages/home/home.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FileSelectDirective } from 'ng2-file-upload';
import { DataTablesModule } from 'angular-datatables';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LoginComponent } from './_pages/login/login.component';
import { ResultadosComponent } from './_pages/diagnostico/resultados/resultados.component';
import { PreguntasFrecuentesComponent } from './_pages/contacto/preguntas-frecuentes/preguntas-frecuentes.component';
import { AsistenciaComponent } from './_pages/contacto/asistencia/asistencia.component';
import { AdministracionComponent } from './_pages/administracion/administracion.component';
import { ContenidoQinfoComponent } from './_pages/quieroinformarme/contenido-qinfo/contenido-qinfo.component';
import { ChangePasswordComponent } from './_pages/login/change-password/change-password.component';
import { GestionUsuariosComponent } from './_pages/administracion/gestion-usuarios/gestion-usuarios.component';
import { RestorePasswordComponent } from './_pages/login/restore-password/restore-password.component';
import { LayoutComponent } from './_pages/layout/layout.component';
import { SubmenuComponent } from './_pages/submenu/submenu.component';
import { MaterialModule } from './_pages/material/material.module'; //borrar
import { JwtInterceptor } from './_helper/jwt-interceptor';
import { QuillModule } from 'ngx-quill';
import { MicrositioViewComponent } from './_pages/micrositios/components/micrositio-view/micrositio-view.component';
import { PreguntasComponent } from './_pages/preguntas/preguntas.component';
import { CreateUserComponent } from './_pages/administracion/Modals/create-user/create-user.component';
import { PermitsviewComponent } from './_pages/administracion/Modals/permitsview/permitsview.component';
import { MenuDinamicoComponent } from './_pages/menu-dinamico/menu-dinamico.component';
import { DataMigrationComponent } from './_pages/administracion/Modals/data-migration/data-migration.component';

import { UploadFileDirective } from './_directives/upload/upload-file.directive';

// Modules
import { SharedModuleModule } from './_shared/module/shared-module/shared-module.module';

// Components
import { MapComponent } from './_pages/quieroinformarme/map/map.component';

// Shared
import { DropDownRadioComponent } from './_shared/components/drop-down-radio/drop-down-radio.component';
import { FooterComponent } from './_shared/components/footer/footer.component';
import { MiscrositiosBannerComponent } from './_shared/components/miscrositios-banner/miscrositios-banner.component';
import { BarraNombreUsuarioComponent } from './_shared/components/barra-nombre-usuario/barra-nombre-usuario.component';
import { ViewHtmlComponent } from './_shared/modals/view-html/view-html.component';
import { ConfirmationModalComponent } from './_shared/modals/confirmation-modal/confirmation-modal.component';
import { AlertModalComponent } from './_shared/modals/alert-modal/alert-modal.component';
import { TableDynamicComponent } from './_shared/components/table-dynamic/table-dynamic.component';
import { PlantillaGuiaComponent } from './_pages/quieroaprender/plantilla-guia/plantilla-guia.component';

@NgModule({
  declarations: [
    AppComponent,
    FileSelectDirective,
    BannersupComponent,
    MenuComponent,
    MicrositioViewComponent,
    BannerprincipalComponent,
    EstrategiaComponent,
    DiagnosticoComponent,
    QuieroinformarmeComponent,
    ContactoComponent,
    FooterComponent,
    AutodiagnosticoComponent,
    HomeComponent,
    DropDownRadioComponent,
    LoginComponent,
    ResultadosComponent,
    PreguntasFrecuentesComponent,
    AsistenciaComponent,
    AdministracionComponent,
    ContenidoQinfoComponent,
    ChangePasswordComponent,
    GestionUsuariosComponent,
    RestorePasswordComponent,
    LayoutComponent,
    SubmenuComponent,
    MiscrositiosBannerComponent,
    BarraNombreUsuarioComponent,
    PreguntasComponent,
    CreateUserComponent,
    PermitsviewComponent,
    ViewHtmlComponent,
    MenuDinamicoComponent,
    TableDynamicComponent,
    DataMigrationComponent,
    UploadFileDirective,
    MapComponent,
  ],
  imports: [
    BrowserModule,
    routing,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgbModule,
    MatIconModule,
    NgxSpinnerModule,
    NgMultiSelectDropDownModule.forRoot(),
    DataTablesModule,
    MatButtonModule,
    MaterialModule,
    QuillModule,
    SharedModuleModule,
    LeafletModule
  ],
  entryComponents: [
    PermitsviewComponent,
    ViewHtmlComponent,
    ConfirmationModalComponent,
    AlertModalComponent,
    ConfirmationModalComponent,
    CreateUserComponent,
    ContenidoQinfoComponent
  ],
  exports: [FormsModule, ReactiveFormsModule],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
