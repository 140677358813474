import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TableroService } from 'src/app/_services/gestion-secretarias/tablero.service';
import { ContenidoQinfoComponent } from './contenido-qinfo/contenido-qinfo.component';

@Component({
  selector: 'app-quieroinformarme',
  templateUrl: './quieroinformarme.component.html',
  styleUrls: ['./quieroinformarme.component.scss']
})
export class QuieroinformarmeComponent implements OnInit {
  
  tableros: any[] = [];

  constructor(
    private modalService: NgbModal,
    private tableroService: TableroService
  ) { }

  ngOnInit() {
    this.tableroService.getBoards(1, 0)
    .subscribe(response => {
      this.tableros = response;
    });
  }

  modal(nombre: string, url: string) {
    const ref = this.modalService.open(ContenidoQinfoComponent, {
      size: 'xl',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
    ref.componentInstance.nombre = nombre;
    ref.componentInstance.url = url; 
  }

  openUrl(link: string) {
    let url = link.includes('http://') || link.includes('https://')? link : `http://${link}`;
    window.open(url, "_blank");
  }
}
