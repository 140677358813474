export class user {
    idUsuario: String;
    clave: String;
    estado: String;
    primerNombre: String;
    primerApellido: String;
    correoElectronico: String;
    idRol: number;
    segundoNombre: String;
    segundoApellido: String;
    celular: String;
    idSecretaria: number;
    cargo: String;
    genero: String;
    tipoDocumento: String;
    numeroIdentificacion: String;
}